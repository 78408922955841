import { Dialog, DialogPanel } from "@headlessui/react";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { hide } from "../../redux/slices/alertdialog";

const AlertDialog = () => {
    const state = useSelector(state => state.alertDialogSlice);
    const dispatcher = useDispatch();
    const open = state.visible;

    return <Dialog open={open} onClose={() => dispatcher(hide())} className="relative z-10">
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                <DialogPanel
                    transition
                    className="relative transform overflow-hidden rounded-lg bg-white rounded-primary sm:w-max w-full text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                    {state.child}
                </DialogPanel>
            </div>
        </div>
    </Dialog>;
};

export default AlertDialog;