import React from "react";
import { useDispatch } from "react-redux";

import { ReactComponent as Checkmark } from "../../icons/checkmark.svg";
import Button from "../../global/components/buttton";
import { hide } from "../../redux/slices/alertdialog";

const AlertDialogSuccess = ({ title, description, onClick }) => {
    const dispatch = useDispatch();

    return <div className="flex flex-col justify-between items-center sm:py-10 sm:px-14 p-5 max-w-[500px] w-full">
        <div className="border-primary-dark p-1 border-2 sm:h-[100px] sm:w-[100px] w-[70px] h-[70px] rounded-[50%]">
            <div className="bg-primary-dark rounded-[50%] h-[100%] w-[100%] flex items-center justify-center">
                <Checkmark height="70%" width="70%" />
            </div>
        </div>

        <h1 className="text-primary-dark font-semibold sm:text-xl text-lg my-5">{title}</h1>
        <h1 className="sm:text-md text-sm text-center mb-9">{description}</h1>

        <Button child="OK" extraCss="w-[100%] h-11" onClick={() => {
            onClick();
            dispatch(hide());
        }} />
    </div>;
};

export default AlertDialogSuccess;