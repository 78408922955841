import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import fetch from "./../axios/manager";
import AlertDialogFailure from "../dialogs/alertdialog/alertdialog_failure";
import AlertDialogSuccess from "../dialogs/alertdialog//alertdialog_success";
import * as Loader from "../dialogs/loader/loaderop";
import showToast from "../dialogs/loader/toast";
import { STRIPE_PUBLISHABLE_URL } from '../global/vars/env';
import { show } from "../redux/slices/alertdialog";

const IDVScreen = () => {
    const { uuid } = useParams();
    const dispatcher = useDispatch();
    const [stripe, setStripe] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const operations = async () => {
        const [data, idvError, statusCode] = await fetch({
            route: "users/create-verification-session",
            requestType: "post",
            body: {
                uuid: uuid,
            },
        });

        if (statusCode === 409) {
            Loader.hide();
            setSubmitted(true);
            dispatcher(show({
                child: <AlertDialogSuccess title="Success"
                    description="Your account is already verified. you can close this now"
                    onClick={() => {
                        if (window.processSucess) {
                            window.processSucess();
                        }
                    }}
                />
            }));
            return;
        }

        if (idvError != null) {
            Loader.hide();
            showToast(idvError);
            return;
        }

        const secret = data.secret;

        const obj = await stripe.verifyIdentity(secret);

        Loader.hide();

        const error = obj.error;
        console.log(obj);

        if (error) {
            dispatcher(show({
                child: <AlertDialogFailure title="Error"
                    description={`Operation failed with code - ${error.code}`}
                    onClick={() => {
                        if (window.processFailure) {
                            window.processFailure();
                        }
                    }} />
            }));
            return;
        }
        else {
            setSubmitted(true);

            dispatcher(show({
                child: <AlertDialogSuccess title="Submitted"
                    description="Your information has been submitted"
                    onClick={() => {
                        if (window.processSucess) {
                            window.processSucess();
                        }
                    }}
                />
            }));
            return;
        }
    };

    const init = async () => {
        Loader.show();

        const obj = await loadStripe(STRIPE_PUBLISHABLE_URL);

        setStripe(obj);
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (stripe == null) return;

        operations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe]);

    return <div className="h-[100dvh] flex flex-col">
        <div className="flex flex-col w-[100%] justify-center h-[80vh] items-center">
            {submitted && <h3 className="text-sm font-medium">Request submitted you can go back now.</h3>}
        </div>
    </div>;
};

export default IDVScreen;