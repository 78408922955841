import { configureStore } from "@reduxjs/toolkit";

import AlertDialogReducer from "./slices/alertdialog";
import TopLoadingBarReducer from "./slices/toploadingbar";

const store = configureStore({
    reducer: {
        alertDialogSlice: AlertDialogReducer,
        loadingBarSlice: TopLoadingBarReducer,
    },
});

export default store;