import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./global/styles/index.css";

import Loader from "./dialogs/loader/loader";
import LoadingBarComponent from "./dialogs/loader/toploadingbar";
import { ToastContainer } from 'react-toastify';
import AlertDialog from "./dialogs/alertdialog/alertdialog";
import { Provider } from 'react-redux';
import store from "./redux/store";
import IDVScreen from './screens/idv';
import PageNotFound from "./screens/page_not_found";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Loader />
    <LoadingBarComponent />
    <ToastContainer
      progressClassName="rounded-primary"
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      stacked
      rtl={false}
      pauseOnFocusLoss
      draggable
      theme="colored"
    />
    <AlertDialog />

    <BrowserRouter>
      <Routes>
        <Route path="/:uuid" element={<IDVScreen />} />
        <Route
          path="*"
          element={<PageNotFound />}
        />
      </Routes>
    </BrowserRouter>
  </Provider>
);
